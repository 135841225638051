/* BookLists.css */
.book-lists {
    display: flex;
    margin: 20px;
}

.sidebar {
    flex: 1;
    padding: 20px;
    background-color: transparent; /* Updated to make background transparent */
    border-right: 2px solid #ddd;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
    margin: 5px 0;
    background-color: #5ec5d4; /* This is the button background color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    color: #fceec2;
    
}

.sidebar li:hover {
    background-color: #dcdcdc; /* Color change on hover */
}

.main-content {
    flex: 3;
    padding: 20px;
}

.recommended-books {
    padding: 10px;
    background-color: transparent; /* Make this transparent as well */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

/* Add more styles as needed */
