.App-header {
  background-image: url('/images/books2.png'); /* Path is correct for the public/images directory */
  background-size: cover;
  background-repeat: no-repeat; /* If you don't want the image to tile */
  text-align: center;
  position: fixed;
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  top: 0;
  left: 0;
  z-index: 10; /* Ensures the header stays above other content */
}


.App-header h1 {
  color: white;
  font-style: italic; /* Adjust the font style as needed */
  padding: 20px 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  position: absolute;
  bottom: 0;
  width: 100%;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  padding: 14px 16px;
  display: inline-block;
}

nav ul li a:hover {
  background-color: #ddd;
  color: black;
}

body {
  padding-top: 300px; /* This should match the new height of your header */
  background-image: url('/images/bg1.png'); /* Replace with your actual image path */
  background-repeat: repeat;
}

/* Other styles */
