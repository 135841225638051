/* StaticBlogPost.css */

.blog-post {
    margin: 20px auto;
    max-width: 800px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    text-align: center;
}

.blog-post-image {
    width: 30%; /* Resize the image to 30% of its parent container's width */
    height: auto; /* Maintain the aspect ratio of the image */
    object-fit: cover; /* This ensures that the image covers the area appropriately */
    margin: 0 auto; /* Center the image within the container */
}

.blog-post-content {
    padding: 20px;
}

.blog-post h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 10px;
}

.blog-post-date {
    color: #666;
    font-style: italic;
    margin-bottom: 20px;
}

/* Add more styles as needed */
