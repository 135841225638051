/* About.css */
.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.about-container h1 {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.about-container p {
    color: #555;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 15px;
}

.sophia-image {
    width: 50%;
    height: auto; /* This ensures the aspect ratio is maintained */
    /* Other styles... */
  }
  

/* Additional styling can be added as needed */
