.book-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.book-item {
    display: flex; /* Use flexbox to allow items to be side by side */
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Add a gap between items */
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    align-items: center;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    max-width: calc(30.33% - 20px); /* Three items per row with a 20px gap */
    flex-basis: calc(33.33% - 20px); /* Three items per row with a 20px gap */
}

.book-item:hover {
    transform: translateY(-5px);
}

.book-item img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.book-details {
    flex-grow: 1; /* Allow the text to grow and fill the available space */
    overflow: hidden; /* Hide overflowing content */
}

.book-item h3 {
    margin: 0;
    font-size: 18px;
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add an ellipsis for hidden text */
}

.book-item p {
    font-size: 14px;
    color: #555;
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add an ellipsis for hidden text */
}

.book-item a {
    color: #40abbf; /* Change the link color here */
    text-decoration: none;
    font-weight: bold;
}

.book-item a:hover {
    text-decoration: underline;
}

.buy-link {
    color: #40abbf; /* Change the link color here */
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px; /* Adjust margin as needed */
    display: block; /* Make it a block element to place it below the book details */
}
